<template>
  <v-container :fluid="branchesDisplayMode=='grid'">
    <v-snackbar v-if="error" color="error" dark timeout="2000">Une erreur est survenue</v-snackbar>
    <v-dialog max-width="800" v-model="creationDialog">
      <license-creation @created="licenseCreated" @cancel="creationDialog=false"/>
    </v-dialog>

    <v-dialog v-model="showCloudUsageDialog" max-width="600">
      <v-card v-if="selectedCompanyForCloudUsage">
        <v-card-title>Utilisation du cloud<v-spacer/><v-btn icon @click="showCloudUsageDialog = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-subtitle>{{selectedCompanyForCloudUsage.name}}</v-card-subtitle>
        <v-card-text>
          <cloud-usage :company="selectedCompanyForCloudUsage"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showInvoicingDetail" max-width="600">
      <v-card v-if="selectedBranch">
        <v-card-title>Facturation<v-spacer/><v-btn icon @click="showInvoicingDetail = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-subtitle>{{selectedBranch.name}}</v-card-subtitle>
        <v-card-text>
          <invoicing-inconsistency-single-branch-card :branch="selectedBranch"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showGenepi" max-width="800">
      <v-card v-if="genepiUrlToShow">
        <v-card-title>Genepi - Parc Informatique<v-spacer/><v-btn icon @click="showGenepi = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <iframe :src="genepiUrlToShow" height="800" style="width: 100%; padding: 0; margin: 0;" frameborder="0" allowfullscreen/>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col>
        <v-form @submit.prevent="fetch">
          <div class="d-flex">
            <v-text-field v-if="!showAllBranches" v-model="searchField" append-icon="search" clearable
                          label="Rechercher un UUID d'agence ou de société / un numéro de license / un nom d'agence ou de société / une IP"
                          outlined spellcheck="false"/>
            <v-spacer v-else/>
            <v-checkbox class="ml-2" label="Toutes les agences" v-model="showAllBranches"/>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-list>

            <!-- Dialogue d'affichage de champ -->
            <v-dialog max-width="800" v-model="showCustomFieldDialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn absolute color="secondary" top right fab small v-bind="attrs" v-on="on">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Clés de configuration à afficher</v-card-title>
                <v-card-subtitle>Pour toutes les agences affichées ci-dessous, les valeurs des clés sélectionnées seront affichées directement dans la liste.</v-card-subtitle>
                <v-card-text>
                  <license-fields-select v-model="customFieldsToShow"/>
                </v-card-text>
                  <v-card-actions>
                    <v-spacer/><v-btn color="primary" text @click="showCustomFieldDialog = false">Terminer</v-btn>
                  </v-card-actions>
              </v-card>
            </v-dialog>

            <v-btn v-if="isUserSupport" color="green accent-4" absolute top right fab small style="right: 65px;" @click="creationDialog=true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-tooltip left v-if="showAllBranches">Télécharger au format CSV
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" color="secondary" absolute top right fab small style="right: 115px;" :loading="csv.loading" @click="downloadAllBranchesAsCSV">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-snackbar color="error" v-model="csv.error">Une erreur est survenue lors du téléchargement de la liste des agences au format CSV</v-snackbar>


            <!-- Liste des sociétés -->

            <div v-if="!showAllBranches">
            <v-subheader v-if="companyResult.length == 0">Aucune société correspondante</v-subheader>
            <v-subheader v-else>{{ companyResult.length }} société{{ companyResult.length > 1 ? "s" : "" }}
            </v-subheader>
            <v-list-item v-for="(c,i) in companyResult" :key="'c'+i">
              <v-list-item-content>
                <v-list-item-title v-text="c.name"/>
                <v-list-item-subtitle>
                  <pre class="d-inline mr-1">{{ c.uuid }}</pre>
                  <v-tooltip top>N'afficher que les agences de cette société<template v-slot:activator="{ on, attrs }"><v-btn icon x-small @click="searchFor(c.uuid)"><v-icon v-on="on" v-bind="attrs" x-small>mdi-filter</v-icon></v-btn></template></v-tooltip>
                  <v-tooltip top>Afficher les logs pour cette société<template v-slot:activator="{ on, attrs }"><v-btn icon v-on="on" v-bind="attrs" x-small @click="goToLogs({companyUuid: c.uuid})"><v-icon x-small>mdi-notebook</v-icon></v-btn></template></v-tooltip>
                  <computer-park-button :company-uuid="c.uuid" @click="showComputerPark(c.uuid)"/>
                  <v-tooltip top>Afficher l'utilisation YodaCloud de cette société<template v-slot:activator="{ on, attrs }"><v-btn icon v-on="on" v-bind="attrs" x-small @click="showCloudUsageDialog = true; selectedCompanyForCloudUsage = c;"><v-icon x-small>mdi-cloud</v-icon></v-btn></template></v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider/>
            </div>

            <!-- Affichage des agences -->

            <v-subheader v-if="branchResult.length == 0">Aucune agence correspondante</v-subheader>
            <v-subheader v-else>
              <!-- Nombre de résultat et filtre sur les agences inactives -->
              <span class="mr-2">{{ branchResult.length }} agence{{ branchResult.length > 1 ? "s" : "" }}</span><v-switch dense v-model="filterActiveBranches" label="Masquer les agences inactives"/><span v-if="filterActiveBranches" class="ml-4">({{inactiveBranchesMaskedCount}} masquée{{ inactiveBranchesMaskedCount > 1 ? "s" : "" }})</span>
              <v-spacer/>
              <!-- Choix du mode d'affichage -->
              <v-btn-toggle mandatory dense v-model="branchesDisplayModeBtnGroup">
                <v-btn><v-icon>list</v-icon></v-btn>
                <v-btn><v-icon small>mdi-grid</v-icon></v-btn>
              </v-btn-toggle>
            </v-subheader>

            <!-- Mode d'affichage liste -->
            <div v-if="branchesDisplayMode==='list'">
              <list-item-branch v-for="(b,i) in filteredBranches" :key="'b'+i" :branch="b" :custom-fields-to-show="customFieldsToShow" :show-activation-status="filterActiveBranches" @branch-selected="displayBranch"/>
            </div>

            <!-- Mode d'affichage tableau / grille -->
            <v-list-item class="pa-0" v-if="branchesDisplayMode==='grid'">
              <branch-table @branch-selected="displayBranch" @branch-selected-for-computer-park="showComputerPark($event.companyUuid, $event.uuid)" :branches="filteredBranches" :conf-to-show="customFieldsToShow" :filter-active-branches="filterActiveBranches"/>
            </v-list-item>

          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!--Dialogue de détail-->
    <v-dialog :value="showBranchDetail" @click:outside="closeDialog" max-width="1100">
      <v-dialog :value="showBranchEditionDialog" max-width="600">
        <branch-edit-card :branch="selectedBranch" @close="showBranchEditionDialog=false" @should-update="updateSelectedBranch"/>
      </v-dialog>

      <v-card v-if="selectedBranch" :loading="loading">
        <v-card-title class="float-right">
          <v-btn icon @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title>{{ selectedBranch.shopName }}<span class="caption ml-2">({{ selectedBranch.name }})<v-tooltip top>Modifier cette agence<template v-slot:activator="{ on, attrs }"><v-btn icon v-on="on" v-bind="attrs" small @click="showBranchEditionDialog=true"><v-icon small>edit</v-icon></v-btn></template></v-tooltip></span></v-card-title>
        <v-card-subtitle>{{ selectedBranch.companyName }}</v-card-subtitle>
        <v-card-subtitle class="pb-0">Agence</v-card-subtitle>
        <v-card-subtitle>
          <pre class="d-inline">{{ selectedBranch.uuid }}</pre>
            <v-tooltip top>Afficher les logs pour cette agence<template v-slot:activator="{ on, attrs }"><v-btn icon v-on="on" v-bind="attrs" x-small @click="goToLogs({branchUuid: selectedBranch.uuid})"><v-icon x-small>mdi-notebook</v-icon></v-btn></template></v-tooltip>
            <computer-park-button :branch-uuid="selectedBranch.uuid" @click="showComputerPark(selectedBranch.companyUuid, selectedBranch.uuid)"/>
            <v-tooltip top>Afficher l'analyse facturation de cette agence<template v-slot:activator="{ on, attrs }"><v-btn icon v-on="on" v-bind="attrs" x-small @click="showInvoicingDetail = true"><v-icon x-small>mdi-currency-eur</v-icon></v-btn></template></v-tooltip>
            <v-tooltip top>Afficher la facturation de cette agence dans Axonaut<template v-slot:activator="{ on, attrs }"><v-btn icon v-on="on" v-bind="attrs" x-small v-if="selectedBranch.license.conf['support.axonaut.orgid']" @click="openInNew('https://axonaut.com/business/company/show/'+selectedBranch.license.conf['support.axonaut.orgid'])"><span style="position: relative;"><v-icon small>mdi-folder-open</v-icon><v-icon x-small style="position: absolute; left: 2px; top: 2px; transform: scale(.6); color: white;">mdi-currency-eur</v-icon></span></v-btn></template></v-tooltip>
        </v-card-subtitle>
        <v-card-subtitle class="pb-0">Société</v-card-subtitle>
        <v-card-subtitle>
          <pre class="d-inline">{{ selectedBranch.companyUuid }}</pre>
          <v-tooltip top>Afficher les logs pour cette société<template v-slot:activator="{ on, attrs }"><v-btn icon v-on="on" v-bind="attrs" x-small @click="goToLogs({companyUuid: selectedBranch.companyUuid})"><v-icon x-small>mdi-notebook</v-icon></v-btn></template></v-tooltip>
          <computer-park-button :company-uuid="selectedBranch.companyUuid" @click="showComputerPark(selectedBranch.companyUuid)"/>
          <v-tooltip top>Afficher l'utilisation YodaCloud de cette société<template v-slot:activator="{ on, attrs }"><v-btn icon v-on="on" v-bind="attrs" x-small @click="showCloudUsageDialog = true; selectedCompanyForCloudUsage = {name: selectedBranch.companyName, uuid: selectedBranch.companyUuid};"><v-icon x-small>mdi-cloud</v-icon></v-btn></template></v-tooltip>
        </v-card-subtitle>
        <license-detail :selected-branch="selectedBranch" @should-update="updateSelectedBranch"/>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import LicenseFieldsSelect from "@/components/LicenseFieldsSelect";
import LicenseDetail from "@/components/LicenseDetail";
import {LicenseBusiness} from "@/business/license-business";
import LicenseCreation from "@/components/LicenseCreation";
import {mapState} from "vuex";
import ListItemBranch from "@/components/ListItemBranch";
import BranchTable from "@/components/BranchTable";
import CloudUsage from "@/components/CloudUsage";
import InvoicingInconsistencySingleBranchCard from "@/components/InvoicingInconsistencySingleBranchCard";
import BranchEditCard from "@/components/BranchEditCard.vue";
import ComputerParkButton from "@/components/ComputerParkButton.vue";

export default {
  name: 'Home',
  components: {
    ComputerParkButton,
    BranchEditCard,
    InvoicingInconsistencySingleBranchCard,
    CloudUsage, BranchTable, ListItemBranch, LicenseCreation, LicenseDetail, LicenseFieldsSelect},
  data: () => ({
    searchField: "",
    currentSearch: null,
    showAllBranches: false,
    companyResult: [],
    branchResult: [],
    licenseResult: [],
    loading: false,
    error: false,
    showBranchDetail: false,
    selectedBranch: null,
    filterActiveBranches: true,
    customFieldsToShow: [],
    creationDialog: false,
    branchesDisplayModeBtnGroup: 0,
    selectedCompanyForCloudUsage: null,
    showCloudUsageDialog: null,
    showCustomFieldDialog: null,
    showInvoicingDetail: false,
    showBranchEditionDialog: false,
    showGenepi: false,
    genepiUrlToShow: null,
    csv: {
      error: false,
      loading: false,
    }
  }),
  timers: {
    fetch: {time: 500}
  },
  watch: {
    searchField() {
      this.$timer.stop("fetch");
      this.$timer.start("fetch");
    },
    customFieldsToShow(newVal) {
      window.localStorage.setItem('yfx-license.prefs.custom-fields', JSON.stringify(newVal));
    },
    branchesDisplayModeBtnGroup(newVal) {
      window.localStorage.setItem('yfx-license.prefs.branch-display-mode', JSON.stringify(newVal));
    },
    showAllBranches(newVal) {
      if (newVal) {
        this.fetch();
      } else {
        if (!this.searchField || !this.searchField.length <= 1) {
          this.branchResult = [];
          this.companyResult = [];
        } else this.fetch();
      }
    }
  },
  mounted() {
    let searchQuery = this.$route.query.q;
    if (searchQuery) {
      if (searchQuery === '%%') this.showAllBranches = true;
      else this.searchField = searchQuery;
    }
    if (this.$route.name === "Branch") {
      this.fetchAndSelectSingle(this.$route.params.branchUuid)
    }

    // Restitution des champs personnalisés
    let previousSelectionStr = window.localStorage.getItem('yfx-license.prefs.custom-fields');
    if (previousSelectionStr) {
      try {
        let previousSelection = JSON.parse(previousSelectionStr);
        if (Array.isArray(previousSelection)) {
          this.customFieldsToShow = previousSelection;
        }
      } catch (e) {
        console.error(e);
      }
    }
    let previousDisplayStr = window.localStorage.getItem('yfx-license.prefs.branch-display-mode');
    if (previousDisplayStr && previousDisplayStr >= 0 && previousDisplayStr <= 1) {
      this.branchesDisplayModeBtnGroup = Number.parseInt(previousDisplayStr);
    }
  },
  computed: {
    ...mapState("auth", ['userAuthorisations']),
    filteredBranches() {
      if (this.filterActiveBranches) {
        return this.branchResult.filter((b) => b.licenseActivationStatus === 'ACTIVE');
      } else return this.branchResult;
    },
    inactiveBranchesMaskedCount() {
      if (this.filterActiveBranches) {
        return this.branchResult.filter((b) => b.licenseActivationStatus !== 'ACTIVE').length;
      } else return 0;
    },
    branchesDisplayMode() {
      return this.branchesDisplayModeBtnGroup === 0 ? 'list' : 'grid';
    },
    searchString() {
      return this.showAllBranches ? '%%' : this.searchField;
    }
  },
  filters: {
    ...LicenseBusiness.filters
  },
  methods: {
    isUserSupport() {
      if (Array.isArray(this.userAuthorisations)) {
        for (let i = 0; i < this.userAuthorisations.length; i++) {
          if (this.userAuthorisations[i].authorization.role === "admin" || this.userAuthorisations[i].authorization.role === "support" || this.userAuthorisations[i].authorization.role === "root") {
            return true;
          }
        }
        return false;
      } else return false;
    },
    fetch() {
      if (this.searchString && this.searchString.length > 1 && this.currentSearch !== this.searchString) {
        this.currentSearch = this.searchString;
        this.$router.push({path: "/", query: {q: this.searchField}});
        this.loading = true;
        this.$http.get(this.$config.apiUrl + '/v1/search', {params: {q: this.currentSearch}})
            .then((r) => {
              this.companyResult = r.data.companies;
              this.branchResult = r.data.branches;
              this.licenseResult = r.data.licenses;
            }).catch(() => this.error = true)
            .finally(() => this.loading = false);
      } else if (!this.loading) { // Si on est déjà en train de charger, on ne touche pas à la liste, la fin du chargement en cours va mettre à jour le contenu
        this.companyResult = [];
        this.branchResult = [];
      }
    },
    fetchAndSelectSingle(branchUuid) {
      this.loading = true;
      this.$http.get(this.$config.apiUrl + '/v1/branches/' + branchUuid)
          .then((r) => {
            this.selectedBranch = r.data;
            this.showBranchDetail = true;
          }).catch(() => this.error = true)
          .finally(() => this.loading = false);
    },
    displayBranch(b) {
      this.$router.push({path: '/branches/' + b.uuid, query: {fromQ: this.currentSearch}})
      this.selectedBranch = b;
      this.showBranchDetail = true;
    },
    closeDialog() {
      let routerPush = {path: "/"};
      if (this.$route.query.fromQ) {
        routerPush.query = {q: this.$route.query.fromQ};
      }
      this.$router.push(routerPush);
      this.showBranchDetail = false;
    },
    updateSelectedBranch() {
      this.fetchAndSelectSingle(this.selectedBranch.uuid);
    },
    licenseCreated(createdBranch) {
      this.creationDialog = false;
      this.displayBranch(createdBranch);
    },
    searchFor(query) {
      this.searchField = query;
      this.fetch();
      this.$nextTick(() => this.$timer.stop("fetch"))
    },
    goToLogs(payload) {
      if (this.$store.state.auth && Array.isArray(this.$store.state.auth.userApps)) {
        let logApp = null;
        let userApps = this.$store.state.auth.userApps;
        for (let i = 0; i < userApps.length; i++) {
          if (userApps[i].identifier === 'global-logs-viewer') {
            logApp = userApps[i];
            break;
          }
        }
        if (!logApp) {
          alert("Pas de droit, pas de chocolat. Votre utilisateur n'a pas les droits d'accès à l'application de log.");
        } else {
          let url = new URL(logApp.url);
          if (payload.companyUuid) url.searchParams.append("c", payload.companyUuid);
          else if (payload.branchUuid) url.searchParams.append("b", payload.branchUuid);
          window.open(url, '_blank')
        }
      }
    },
    showComputerPark(companyUuid, branchUuid = false) {
      if (this.$store.state.auth && Array.isArray(this.$store.state.auth.userApps)) {
        let computerParkApp = null;
        let userApps = this.$store.state.auth.userApps;
        for (let i = 0; i < userApps.length; i++) {
          if (userApps[i].identifier === 'yfx-computer-park') {
            computerParkApp = userApps[i];
            break;
          }
        }
        if (!computerParkApp) {
          alert("Pas de droit, pas de chocolat. Votre utilisateur n'a pas les droits d'accès à l'application Yodaforex Genepi.");
        } else {
          let computerParkUrl = `${computerParkApp.url}/#/companies/${companyUuid}`;
          if (branchUuid) {
            computerParkUrl+=`/branches/${branchUuid}`
          }
          this.genepiUrlToShow = computerParkUrl += "?embedded";
          this.showGenepi = true;
        }
      }
    },
    openInNew(target) {
      window.open(target, '_blank');
    },
    downloadAllBranchesAsCSV() {
      this.csv.loading = true;
      this.csv.error = false;
      this.$http({
        url: this.$config.apiUrl + "/v1/branches?asCSV&active",
        method: 'GET',
        headers: {'Accept': 'text/csv'},
        responseType: 'blob', // important
      })
          .then((response) => {
            // Downloading the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'all-yodaforex-licenses.csv');
            document.body.appendChild(link);
            link.click();
          })
          .catch(() => this.csv.error = true)
          .finally(() => this.csv.loading = false);
    },
  }
}
</script>
